








import { VueConstructor } from 'vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { IToastMixin, ToastMixin } from '../../../shared'

import { CartItem } from '../../molecules/CartItem'

import { singleCartItemVariants } from './SingleCartItem.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 *
 * @emits [removeFromCartCb]
 */
@Component<SingleCartItem>({
  name: 'SingleCartItem',
  components: { CartItem }
})
export class SingleCartItem extends Mixins<IToastMixin>(ToastMixin) {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasIterator!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isDisabled!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isGift!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isSample!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isOutOfStock!: boolean

  @Prop({ type: Number, required: false })
  public readonly index!: number

  @Prop({ type: Object, required: true })
  public readonly item!: any

  @Prop({ type: Number, required: false })
  public readonly quantity!: number

  @Prop({ type: Number, required: false })
  public readonly quantityStep!: number

  @Prop({ type: Function, required: false })
  public readonly quantityCb!: (change: number) => Promise<boolean> | boolean

  @Prop({ type: Function, required: false })
  public readonly removeFromCartCb!: (uid: string) => Promise<void>

  @Prop({ required: false, type: Boolean, default: true })
  public shouldDisableQuantity?: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public shouldDisablePerItemQuantity?: boolean

  @Prop({ type: String, required: false })
  public readonly size!: string

  @Prop({ type: String, required: false })
  public readonly sku?: string

  @Prop({ type: String, required: true })
  public readonly uid!: string

  @Prop({ type: String, required: false })
  public readonly volume?: string

  @Prop({ type: String, required: false, default: 'default' })
  public readonly variant!: string

  public get component (): VueConstructor {
    return singleCartItemVariants[this.variant]
  }
}

export default SingleCartItem
