import { VueConstructor } from 'vue'

import { Default } from './variants/Default.vue'
import { Stripped } from './variants/Stripped.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const singleCartItemVariants: Record<string, VueConstructor> = {
  default: Default,
  stripped: Stripped
}
