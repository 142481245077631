



































import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'Stripped'
})
export class Stripped extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly hasIterator!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isDisabled!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isGift!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isSample!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly isOutOfStock!: boolean

  @Prop({ type: Number, required: false })
  public readonly index!: number

  @Prop({ type: Object, required: true })
  public readonly item!: any

  @Prop({ type: Number, required: false })
  public readonly quantity!: number

  @Prop({ type: Function, required: false })
  public readonly quantityCb!: (change: number) => Promise<boolean> | boolean

  @Prop({ type: Function, required: false })
  public readonly removeFromCartCb!: (uid: string) => Promise<void>

  @Prop({ required: false, type: Boolean, default: true })
  public shouldDisableQuantity?: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public shouldDisablePerItemQuantity?: boolean

  @Prop({ type: String, required: false })
  public readonly size!: string

  @Prop({ type: String, required: false })
  public readonly sku?: string

  @Prop({ type: String, required: true })
  public readonly uid!: string

  @Prop({ type: String, required: false })
  public readonly volume?: string

  public get overallPrice (): string {
    return String(this.quantity * this.item.price)
  }
}

export default Stripped
